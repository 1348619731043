<script>
import { inject, ref, computed } from 'vue';
import { subscribeKey } from '@drapejs/core';

import DiscountCode from './DiscountCode';
import ShoppingCartRow from './ShoppingCartRow';

import useContext from '@/composables/useContext';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

export default {
  components: {
    ShoppingCartRow,
    DiscountCode,
  },
  data: () => {
    return {
      showNumericInput: false,
      inputQuantity: '',
    };
  },
  setup() {
    const { cart, channel } = useContext();    
    const { websiteText } = useWebsiteTexts();

    const formatPrice = inject('$formatPrice');
    const subscribe = inject(subscribeKey);

    const selectedDeliveryOptionId = ref('');
    subscribe('__deliveryOptionId', (value)=> {
      selectedDeliveryOptionId.value = value;
    })

    const shippingTotal  = computed(() => {
      const total = (cart.value.deliveries || []).reduce((acc, cur) => {
        acc += cur.selectedDeliveryOptionPrice || 0;
        return acc;
      }, 0);
      return total;
    });

    const shippingOriginalPriceTotal = computed(()=> {
      const originalPrice = (cart.value.deliveries || []).reduce((acc, cur) => {
        acc += cur.selectedDeliveryOptionOriginalPrice || 0;
        return acc;
      }, 0); 

      return originalPrice;
    });   

    const shippingTotalLabel = computed(() => {
      if(!selectedDeliveryOptionId.value){
        return '-';
      }

      if(shippingTotal.value === 0){
        return websiteText('checkout__free_shipping').value;
      }

      return formatPrice(shippingTotal.value, undefined, channel?.value?.locale, cart?.value.currency.id);
    });

    const shippingOriginalPriceTotalLabel = computed(()=> {
      const deliveries = cart.value.deliveries || [];
      if(!selectedDeliveryOptionId.value || deliveries.length === 0 || shippingTotal.value > 0 || shippingOriginalPriceTotal.value === 0){
        return '';
      }
      return formatPrice(shippingOriginalPriceTotal.value, undefined, channel?.value?.locale, cart?.value.currency.id);
    });

    const shippingDetailsLabel = computed(()=> {
      if(!selectedDeliveryOptionId.value) {
        return websiteText('checkout__select_shipping_option').value || 'checkout__select_shipping_option';
      }
      if(shippingTotal.value == 0 && shippingOriginalPriceTotal.value > 0){
        return websiteText('checkout__free_shipping_subcriber_discount').value;
      }
      return '';
    })

    const grandTotal = computed(()=> {
      if(!selectedDeliveryOptionId.value){
        return '-';
      }
      return formatPrice(cart.value?.grandTotal || 0, undefined, channel?.value?.locale, cart?.value.currency.id);
    })

    return {
      websiteText,
      shippingTotalLabel,
      shippingOriginalPriceTotalLabel,
      shippingDetailsLabel,
      grandTotal
    };
  },
  computed: {
    rows() {
      return this.$cart.rows || [];
    },
    rowsGroupedByDelivery() {
      let index = 0;
      return this.rows.reduce((group, row) => {
        const {shippingInterval} = row;
        
        group[shippingInterval.inventoryId] = group[shippingInterval.inventoryId] ?? {shippingInterval: shippingInterval, index: index++, rows: []};
        group[shippingInterval.inventoryId].rows.push(row);
      
        return group;
      }, {});
    },
    hasMultipleDeliveries() {
      return Object.keys(this.rowsGroupedByDelivery).length > 1;
    },
    productsTotal() {
      return this.$formatPrice(this.$cart.totalOrderRow || 0);
    },
    currencyId() {
      return this.$cart?.currency.id;
    },
    countryId() {
      return this.$cart?.countryId;
    },
    channelId() {
      return this.$channel?.systemId;
    },
    isSplitCountryDelivery() {
      return this.$cart.isSplitCountryDelivery || false;
    },
  },
  methods: {
    getDeliveryTitle(rowsGroup) {
      return this.websiteText('checkout__deliery', {
        index: rowsGroup.index + 1
      }).value;
    },
    getDeliverySubTitle(rowsGroup) {
      return this.websiteText('quick_cart__estimated_delivery_time_days', {
        min: rowsGroup.shippingInterval.minimumDeliveryTimeInDays,
        max: rowsGroup.shippingInterval.maximumDeliveryTimeInDays,
      }).value;
    }
  }
};
</script>

<template>
  {{ selectedDeliveryOptionId }}
  <div
    class="shopping-cart"
    :data-cy-cart-country="countryId"
    :data-cy-cart-currency="currencyId"
    :data-cy-cart-channel="channelId"
  >
    <div class="shopping-cart_title">
      {{ websiteText('header_shoppingcart').value }}
    </div>

    <div class="shopping-cart_split-delivery" v-if="isSplitCountryDelivery">
      {{
        websiteText('checkout__split_delivery').value ||
        'checkout__split_delivery'
      }}
    </div>

    <div v-for="(rowsGroup, key) in rowsGroupedByDelivery" :key="key">
      <div
        v-if="hasMultipleDeliveries"
        class="shopping-cart_list-group"
      >
        <span>{{getDeliveryTitle(rowsGroup)}}</span>
        <span>{{getDeliverySubTitle(rowsGroup)}}</span>
      </div>
      <ul class="shopping-cart_list">
        <shopping-cart-row v-for="row in rowsGroup.rows" :row="row" :key="row.id"></shopping-cart-row>
      </ul>
    </div>

    <div class="shopping-cart_discount">
      <discount-code></discount-code>
    </div>

    <div class="shopping-cart_summary">

      <div class="shopping-cart_summary-wrap">
        <div class="shopping-cart_shipping shopping-cart_summary-text shopping-cart_summary-text--small">
          <div>{{ websiteText('checkout__shipping').value }}</div>
            <div class="shopping-cart__shipping-details" v-if="shippingDetailsLabel">
              <span class="shopping-cart__shipping-details--desktop">&nbsp;-&nbsp;{{ shippingDetailsLabel }}</span>
              <span class="shopping-cart__shipping-details--phone">{{ shippingDetailsLabel }}</span>
            </div>
        </div>
        <div class="shopping-cart_summary-amount shopping-cart_summary-amount--small">
          {{ shippingTotalLabel }}
          <span class="shopping-cart__shipping-original-price" v-if="shippingOriginalPriceTotalLabel"> {{ shippingOriginalPriceTotalLabel }}</span>
        </div>
      </div>

      <div class="shopping-cart_summary-wrap">
        <div class="shopping-cart_summary-text shopping-cart_summary-text--small">
          {{ websiteText('checkout__products_total').value }}
        </div>
        <div class="shopping-cart_summary-amount shopping-cart_summary-amount--small">
          {{ productsTotal }}
        </div>
      </div>

      <div class="shopping-cart_summary-wrap">
        <div class="shopping-cart_summary-text">
          {{ websiteText('header_ordertotal').value }}
        </div>
        <div class="shopping-cart_summary-amount">
          {{ grandTotal }}
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.shopping-cart {
  width: 100%;
  right: 0;
  background: var(--color-white);
  color: var(--color-coldGrey200);
  z-index: 9999;
}

.shopping-cart_title {
  font-family: var(--font-header);
  text-transform: uppercase;
  display: block;
  padding-left: 1rem;
}

.shopping-cart_split-delivery {
  padding: 1.25rem 0 1rem;
  border-bottom: 1px solid var(--color-grey900);
  margin: 0 2rem 1rem 2rem;
  font-family: var(--font-proxima-nova-light);
  font-weight: 300;
  font-style: italic;
  font-size: 16px;
}

.shopping-cart_list-group {
  font-weight: bold;
  padding: 0 1rem;
  font-family: var(--font-header);
  margin: 0 2rem;
  display: flex;
  justify-content: space-between;
}

.shopping-cart_list {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  padding: 0 1rem;
}

.shopping-cart_discount {
  padding: 0 1rem;
}

.shopping-cart_summary {
  padding: 0 1rem;
}

.shopping-cart_summary-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}

.shopping-cart_summary-value-wrap {
  border-bottom: 1px solid var(--color-grey700);
}

.shopping-cart_summary-wrap div {
  font-family: var(--font-header);
}

.shopping-cart_summary-text {
  text-transform: uppercase;
  width: 50%;
  font-size: 18px;
}

.shopping-cart_summary-amount {
  font-size: 18px;
  width: 50%;
  text-align: right;
}

.shopping-cart_summary-text--small,
.shopping-cart_summary-amount--small {
  font-size: 12px;
  margin-bottom: -4px;
  text-transform: none;
}

.shopping-cart_summary-value-wrap .shopping-cart_summary-text,
.shopping-cart_summary-value-wrap .shopping-cart_summary-value {
  font-size: 14px;
  font-family: var(--font-body);
}

.shopping-cart-item:last-child {
  border-bottom: none;
}

.shopping-cart_shipping {
  display: flex;
  flex-direction: column;
}

.shopping-cart__shipping-details span {
  font-family: var(--font-proxima-nova-light);
  font-style: italic;
  color: var(--color-neutral-100);
}

.shopping-cart__shipping-details--phone {
  line-height: 24px;
}

.shopping-cart__shipping-details--desktop {
  display: none;
}

.shopping-cart__shipping-original-price {
  text-decoration: line-through;
  color: #797B80;
}

@media(--tabletAndDesktop){
  .shopping-cart_shipping {
    flex-direction: row;
  }

  .shopping-cart__shipping-details--phone {
    display: none;
  }

  .shopping-cart__shipping-details--desktop {
    display: inline;
  }
}

@media (--tablet) {
  .shopping-cart {
    padding: 1.25rem 0;
  }

  .shopping-cart_list {
    padding: 0 1.25rem;
  }

  .shopping-cart_discount {
    padding: 0 1.25rem;
  }

  .shopping-cart_title {
    padding: 0;
    margin: 0 1.25rem;
  }

  .shopping-cart_summary {
    padding: 0 1.25rem;
  }
}

@media (--desktop) {
  .shopping-cart {
    padding: 2.25rem 0;
  }

  .shopping-cart_list {
    padding: 0 2.25rem;
  }

  .shopping-cart_discount {
    padding: 0 2.25rem;
  }

  .shopping-cart_title {
    padding: 0;
    margin: 0 2.25rem;
  }

  .shopping-cart_summary {
    padding: 0 2.25rem;
  }
}
</style>
