<script>
import { useGA4 } from '@distancify-storefront/tracking-gtm';
import ShoppingCart from './ShoppingCart';
import KlarnaCheckout from './KlarnaCheckout';
import useWebsiteTexts from '@/composables/useWebsiteTexts';
import * as sales from '@/commands/sales';

export default {
  components: {
    ShoppingCart,
    KlarnaCheckout,
  },
  setup() {
    const ga4 = useGA4({
      useLegacyEnhancedECommerce: true,
    });

    ga4.beginCheckout();
    const { websiteText } = useWebsiteTexts();

    return {
      websiteText,
    };
  },
  data: () => ({
    previousRoute: null,
    checkoutReportedToGtm: false,
  }),
  computed: {
    channelUrl() {
      return this.$channel?.rootPath;
    },
    pageName() {
      return this.$page.fields._name;
    },
    rows() {
      return this.$cart?.rows || [];
    },
    cartUsp() {
      return this.$channel.cartUsp;
    },
    emptyCart() {
      return this.rows.length === 0;
    },
    showPhoneMandatory() {
      return this.$route.query.phone === 'mandatory';
    },
    showOutOfStock() {
      return this.$route.query.out_of_stock === 'true';
    },
    showDeliveryOptionsChanged() {
      return this.$route.query.delivery_options_changed === 'true';
    },
    errorMessage() {
      const { error, reason } = this.$route.query;
      if (!error) return '';

      return reason === 'instrument_declined'
        ? this.websiteText('checkout__error_intrustment_declined').value
        : this.websiteText('checkout__error_general').value;
    },
    backToStoreRoute() {
      return this.previousRoute || this.channelUrl;
    },
  },
  async mounted() {
    this.previousRoute = localStorage.getItem('checkoutPreviousRoute');
    if (this.showOutOfStock || this.showDeliveryOptionsChanged) {
      await this.$invoke(sales.commands.resetCartToAvailableStock, { url: this.$route.href });
    }
  },
  methods: {
    onGoToPreviousRoute() {
      localStorage.removeItem('checkoutPreviousRoute');
    },
  },
};
</script>

<template>
  <div class="checkout">
    <div class="checkout_header">
      <div class="checkout_header-left">
        <router-link
          :to="backToStoreRoute"
          @click.native="onGoToPreviousRoute()"
          class="checkout_header-back"
        >
          {{ websiteText('backtoshop').value }}
        </router-link>
      </div>
      <div>
        <h1>{{ pageName }}</h1>
      </div>
      <div class="checkout_header-right" data-cy="checkout-usp">{{ cartUsp }}</div>
    </div>
    <div class="checkout_header-small">
      <router-link :to="backToStoreRoute" @click.native="onGoToPreviousRoute()"> {{ websiteText('backtoshop').value || 'checkout__go_back' }}</router-link>
      <div>{{ cartUsp }}</div>
    </div>

    <div v-if="showPhoneMandatory" class="checkout_error">
      {{ websiteText('cart_phonemandatory').value }}
    </div>
    <div v-if="showOutOfStock" class="checkout_error">
      {{ websiteText('checkout__out_of_stock').value }}
    </div>
    <div v-if="showDeliveryOptionsChanged" class="checkout_error">
      {{ websiteText('checkout__delivery_options_changed').value }}
    </div>
    <div v-if="errorMessage" class="checkout_error">
      {{ errorMessage }}
    </div>
    <div class="checkout_container">
      <div v-if="emptyCart" class="checkout_empty-title">
        {{ websiteText('header_shoppingcartisempty').value }}
      </div>
      <div v-if="!emptyCart" class="checkout_shopping-cart">
        <shopping-cart></shopping-cart>
      </div>
      <div v-if="!emptyCart" class="checkout_klarna">
        <klarna-checkout></klarna-checkout>
      </div>
    </div>
  </div>
</template>

<style>
.checkout {
  padding: 3rem 0 0;
}

.checkout_header {
  display: none;
}

.checkout_header-small {
  display: flex;
  font-family: var(--font-proxima-nova-light);
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 1rem 1.5rem;
  font-size: 12px;
  font-weight: 300;
}

.checkout_header-left {
  flex-basis: 100px;
  flex-grow: 1;
}

.checkout_header-right {
  flex-basis: 100px;
  flex-grow: 1;
  text-align: right;
}

.checkout_header-back {
  background-image: url(/ui/img/site/d171_icon_backarrow.svg);
  background-repeat: no-repeat;
  background-size: 14px;
  padding-left: 14px;
  background-position-y: center;
}

a.checkout_header-back:hover,
a.checkout_header-back:focus {
  color: var(--color-coldGrey400);
  border-color: var(--color-coldGrey400);
}

.checkout_empty-title {
  display: block;
  font-family: var(--font-header);
  width: 100%;
  text-align: center;
  height: 400px;
  margin-top: 50px;
}

.checkout_error {
  display: block;
  font-family: var(--font-header);
  width: 100%;
  text-align: center;
  margin: 1rem 0;
  padding: 4px;
  color: var(--color-white);
  background-color: var(--color-red);
}

.checkout_container {
  display: flex;
  flex-direction: column;
}

.checkout_shopping-cart {
  width: 100%;
}

.checkout_klarna {
  width: 100%;
}

@media (--tabletAndDesktop) {
  .checkout {
    max-width: var(--layout-maxWidth);
    margin: auto;
    padding: 1rem;
  }

  .checkout_container {
    flex-direction: row;
  }

  .checkout_shopping-cart {
    width: 50%;
    order: 2;
  }

  .checkout_klarna {
    width: 50%;
    order: 1;
  }

  .checkout_header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 44px;
  }

  .checkout_header-small {
    display: none;
  }
}

@media (--tablet) {
  .checkout_shopping-cart {
    padding-left: 0.75rem;
  }

  .checkout_klarna {
    padding-right: 0.75rem;
  }
}

@media (--desktop) {
  .checkout_shopping-cart {
    padding-left: 1.125rem;
  }

  .checkout_klarna {
    padding-right: 1.125rem;
  }
}
</style>
